import React from "react";
import { Menu } from "antd";
import { Link, withRouter, useLocation } from "react-router-dom";
import {
  PieChartOutlined,
  TeamOutlined,
  DatabaseOutlined,
  BellOutlined,
  DisconnectOutlined,
  UserOutlined,
  CarOutlined,
  WalletOutlined,
} from "@ant-design/icons";

const keys = [
  "dashboard",
  "stock",
  "product",
  "customer",
  "user",
  "order",
  "cancelledorder",
  "category",
  "delivery",
  "wallet",
];

const SideMenu = () => {
  const location = useLocation();

  let selectedKey;
  keys.forEach((i) => {
    const uri = location.pathname.split("/");
    if (uri.indexOf(i) > 0) selectedKey = i;
  });

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Menu
        mode="inline"
        selectedKeys={[selectedKey]}
        // defaultOpenKeys={['sub1']}
        style={{ height: "100%", borderRight: 0, overflowX: "hidden" }}
        theme="light"
        className="no-select">
        <Menu.Item
          key="dashboard"
          icon={<PieChartOutlined style={{ fontSize: "20px" }} />}>
          <Link to="/app/dashboard">Dashboard</Link>
        </Menu.Item>

        <Menu.SubMenu
          key="stocks-products"
          icon={<DatabaseOutlined style={{ fontSize: "20px" }} />}
          title="Stock">
          <Menu.Item key="product">
            <Link to="/app/product">Products</Link>
          </Menu.Item>
          <Menu.Item key="category">
            <Link to="/app/category">Categories</Link>
          </Menu.Item>
          <Menu.Item key="stock">
            <Link to="/app/stock">Stock</Link>
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.Item
          key="customer"
          icon={<TeamOutlined style={{ fontSize: "20px" }} />}>
          <Link to="/app/customer">Customers</Link>
        </Menu.Item>

        <Menu.Item
          key="order"
          icon={<BellOutlined style={{ fontSize: "20px" }} />}>
          <Link to="/app/order">Orders</Link>
        </Menu.Item>
        <Menu.Item
          key="cancelledorder"
          icon={<DisconnectOutlined style={{ fontSize: "20px" }} />}>
          <Link to="/app/cancelledorder">Cancelled Orders</Link>
        </Menu.Item>

        <Menu.Item
          key="wallet"
          icon={<WalletOutlined style={{ fontSize: "20px" }} />}>
          <Link to="/app/wallet">Wallet</Link>
        </Menu.Item>

        <Menu.Item
          key="delivery"
          icon={<CarOutlined style={{ fontSize: "20px" }} />}>
          <Link to="/app/delivery">Delivery Charge</Link>
        </Menu.Item>

        <Menu.Item
          key="user"
          icon={<UserOutlined style={{ fontSize: "20px" }} />}>
          <Link to="/app/user">Users</Link>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default withRouter(SideMenu);
