import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const Loading = (
  <div style={{ padding: "50px" }}>
    <Spin indicator={antIcon} />
  </div>
);

const Dashboard = React.lazy(() => import("../Modules/Dashboard"));
const Stock = React.lazy(() => import("../Modules/Stock"));
const Products = React.lazy(() => import("../Modules/Products"));
const Categories = React.lazy(() => import("../Modules/Categories"));
const Customers = React.lazy(() => import("../Modules/Customers"));
const User = React.lazy(() => import("../Modules/User"));
const Order = React.lazy(() => import("../Modules/Orders"));
const CancelledOrder = React.lazy(() => import("../Modules/CancelledOrders"));
const Delivery = React.lazy(() => import("../Modules/DeliveryCharge"));
const Wallet = React.lazy(() => import("../Modules/Wallet"));

function AppRouter() {
  return (
    <Switch>
      <Route path="/app/dashboard">
        <Suspense fallback={Loading}>
          <Dashboard />
        </Suspense>
      </Route>
      <Route path="/app/product">
        <Suspense fallback={Loading}>
          <Products />
        </Suspense>
      </Route>
      <Route path="/app/category">
        <Suspense fallback={Loading}>
          <Categories />
        </Suspense>
      </Route>
      <Route path="/app/stock">
        <Suspense fallback={Loading}>
          <Stock />
        </Suspense>
      </Route>
      <Route path="/app/customer">
        <Suspense fallback={Loading}>
          <Customers />
        </Suspense>
      </Route>
      <Route path="/app/order">
        <Suspense fallback={Loading}>
          <Order />
        </Suspense>
      </Route>
      <Route path="/app/cancelledorder">
        <Suspense fallback={Loading}>
          <CancelledOrder />
        </Suspense>
      </Route>
      <Route path="/app/delivery">
        <Suspense fallback={Loading}>
          <Delivery />
        </Suspense>
      </Route>
      <Route path="/app/user">
        <Suspense fallback={Loading}>
          <User />
        </Suspense>
      </Route>
      <Route path="/app/wallet">
        <Suspense fallback={Loading}>
          <Wallet />
        </Suspense>
      </Route>

      <Redirect to="/app/dashboard" />
    </Switch>
  );
}

export default AppRouter;
