import React from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import Signin from "./Signin"
import Forgot from "./Forgot"

function Router() {
  return (
    <div style={{ display: "grid", height: "100vh" }}>
      <div
        style={{
          justifySelf: "center",
          alignSelf: "center",
          width: "min(400px, 95%)",
        }}
      >
        <Switch>
          <Route path="/auth/signin" component={Signin} />
          <Route path="/auth/forgot" component={Forgot} />
          <Redirect to="/auth/signin" />
        </Switch>
      </div>
    </div>
  )
}

export default Router
