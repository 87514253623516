import "antd/dist/antd.less"
import "./App.less"
import AppRouter from "./Router"
import axios from "axios"
import { notification } from "antd"
import { getToken, signout } from "./Auth/helpers"
import { QueryClientProvider, QueryClient } from "react-query"

const queryClient = new QueryClient()

function App() {
  axios.interceptors.request.use(
    function (config) {
      config.headers.Authorization = `Bearer ${getToken()}`
      // config.headers["x-service"] = `Service ${getService()}`;
      return config
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error)
    }
  )

  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response?.status === 401) {
        signout(() => {
          window.location.href = "/"
          return
        })
      }

      if (error.message !== undefined && error.message === "Network Error") {
        notification.error({
          message: "Network error",
          description: "Check your network connection",
          placement: "bottomRight",
        })
      }

      return Promise.reject(error)
    }
  )

  return (
    <QueryClientProvider client={queryClient}>
      <AppRouter />
    </QueryClientProvider>
  )
}

export default App
