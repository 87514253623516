import React from "react"
import { Layout } from "antd"
import SideMenu from "../Shared/Sidemenu"
import AppHeader from "../Shared/Header"
import AppRouter from "./AppRouter"

const { Content, Sider } = Layout

const AppLayout = () => {
  const [collapsed, setCollpse] = React.useState(false)
  return (
    <Layout style={{ height: "100vh" }}>
      <AppHeader onCollapse={[collapsed, setCollpse]} />
      <Layout>
        <Sider
          width={180}
          breakpoint="md"
          collapsedWidth={60}
          style={{
            overflow: "hidden",
            height: "100vh",
            position: "fixed",
            left: 0,
            paddingTop: "64px",
            zIndex: 1,
            borderRight: "1px solid #e8e8e8",
          }}
          collapsed={collapsed}
          onCollapse={(c) => {
            setCollpse(c)
          }}
          collapsible
        >
          <SideMenu collapsed={collapsed} />
        </Sider>
        <Layout
          style={{
            padding: "0",
            marginLeft: collapsed ? "60px" : "180px",
            marginTop: "64px",
            height: "calc(100vh - 64px)",
            overflow: "auto",
          }}
        >
          <Content
            style={{
              margin: 0,
              minHeight: 280,
              minWidth: 780,
            }}
          >
            <AppRouter />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  )
}

export default AppLayout
