import React, { useState } from "react";
import { Layout, Typography, Menu, Dropdown, Button, Avatar } from "antd";
import { withRouter } from "react-router-dom";
import { signout, isAuth } from "../../../Auth/helpers";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PoweroffOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";

const { Header } = Layout;
const { Title } = Typography;

const AppHeader = ({ history, onCollapse }) => {
  const [collapsed, setCollapse] = onCollapse;
  const [ddOpen, setDdOpen] = useState(false);
  const user = isAuth();
  const handleSignout = () => {
    signout(() => {
      history.push("/app");
    });
  };

  const menu = (
    <Menu>
      <Menu.Item
        onClick={handleSignout}
        icon={<PoweroffOutlined />}
        danger
        key="signout">
        Sign out
      </Menu.Item>
    </Menu>
  );

  const Dp = ({ picture }) => {
    return !!picture ? (
      <Avatar src={`${process.env.REACT_APP_API_BASE}/${picture}`} />
    ) : null;
  };

  return (
    <Header
      className="header"
      style={{
        position: "fixed",
        zIndex: 2,
        width: "100%",
        padding: "0 20px",
        borderBottom: "1px solid #eaeaea",
      }}>
      <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
        <div style={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
          <Button
            type="text"
            onClick={() => setCollapse((s) => !s)}
            style={{ color: "#fff" }}
            size="large"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          />
          <Title
            level={5}
            style={{ margin: 0, paddingLeft: "15px", color: "#fff" }}>
            Arerov Admin
          </Title>
        </div>
        <Avatar src={<Dp picture={user.picture} />} />
        <Dropdown
          overlay={menu}
          trigger={["click"]}
          onVisibleChange={(e) => setDdOpen(e)}>
          <Button type="text" style={{ color: "#fff" }}>
            {user.name} {ddOpen ? <CaretUpOutlined /> : <CaretDownOutlined />}
          </Button>
        </Dropdown>
      </div>
    </Header>
  );
};

export default withRouter(AppHeader);
