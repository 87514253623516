import React, { useState } from "react"
import { Form, Input, Button, Typography, notification } from "antd"
import { MailOutlined, LockOutlined } from "@ant-design/icons"
import { Link, withRouter } from "react-router-dom"
import axios from "axios"
import { authenticate } from "./helpers"

const { Title } = Typography

const Signin = ({ history }) => {
  const [form] = Form.useForm()
  const [isSubmitting, setSubmitting] = useState(false)

  const handleSubmit = (values) => {
    const url = `${process.env.REACT_APP_API_ADMIN}/auth/signin`
    axios
      .post(url, values)
      .then((response) => {
        authenticate(response, () => {
          history.push("/app")
        })
      })
      .catch((error) => {
        if (!!error.response) {
          notification.error({
            message: "Authentication faild",
            description: error.response.data.message,
            placement: "bottomRight",
          })
        }
        setSubmitting(false)
      })
  }

  return (
    <div
      style={{ background: "#fff", padding: "20px" }}
      className="shadow-md rounded-md"
    >
      <Title level={4} style={{ textAlign: "center" }}>
        Sign in
      </Title>

      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Please enter email" },
            { type: "email", message: "Invalid email" },
          ]}
        >
          <Input
            prefix={<MailOutlined className="site-form-item-icon" />}
            size="large"
          />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please enter password" }]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            size="large"
          />
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit" loading={isSubmitting}>
            Sign in
          </Button>
        </Form.Item>
      </Form>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button type="link">
          <Link to="/auth/forgot">Forgot password?</Link>
        </Button>
      </div>
    </div>
  )
}

export default withRouter(Signin)
