import React, { useState } from "react"
import { Form, Input, Button, Typography, notification } from "antd"
import { MailOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import axios from "axios"

const { Title } = Typography

const Forgot = ({ history }) => {
  const [form] = Form.useForm()
  const [isSubmitting, setSubmitting] = useState(false)

  const handleSubmit = (values) => {
    const url = `${process.env.REACT_APP_API_ADMIN}/auth/reset`
    axios
      .post(url, values)
      .then((response) => {
        notification.success({
          message: "Password reset",
          description: response.data.message,
          placement: "bottomRight",
        })
        history.push("/auth/signin")
      })
      .catch((error) => {
        if (error.response.status === 406) {
          notification.error({
            message: "Notice",
            description: error.response.data.message,
            placement: "bottomRight",
          })
        }
        setSubmitting(false)
      })
  }

  return (
    <div
      style={{ background: "#fff", padding: "20px" }}
      className="shadow-md rounded-md"
    >
      <Title level={4} style={{ textAlign: "center" }}>
        Reset password
      </Title>

      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Please enter email" },
            { type: "email", message: "Invalid email" },
          ]}
        >
          <Input
            prefix={<MailOutlined className="site-form-item-icon" />}
            size="large"
          />
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit" loading={isSubmitting}>
            Reset
          </Button>
        </Form.Item>
      </Form>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button type="link">
          <Link to="/auth/signin">Sign in</Link>
        </Button>
      </div>
    </div>
  )
}

export default Forgot
