import React from "react"
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom"
import ProtectedRoute from "./Auth/PrivateRoute"
import Auth from "./Auth"
import App from "./Protected"

function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/auth" component={Auth} />
        <ProtectedRoute path="/app">
          <App />
        </ProtectedRoute>
        <Redirect to="/app" />
      </Switch>
    </BrowserRouter>
  )
}

export default Router
